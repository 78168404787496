.clock {
    display: flex;
    justify-content: center;
    margin: 20px;
}

.time-container {
    position: relative;
    margin: 0 0.25rem;
    height: 70px;
    background: #fff;
}

.time-value,
.time-label {
    width: 60px;
    height: 50px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    z-index: 10;
}

.time-label {
    position: absolute;
    bottom: 0;
    height: 20px;
    font-size: 8px;
    letter-spacing: 2px;
    font-weight: 500;
    z-index: 9;
    box-shadow: none;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.25);
}
